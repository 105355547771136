import PubSub from "pubsub-js";

let menuCollapse = false;
let displayLoader: any = false;
let loadedClient: any;
let loadedTenant: any;
let selectedJourney: object;
let apiBaseUrl: string;
let analyticsHost: string;

const initialize = async () => {
  const config = await fetch("/client-portal.json").then((it) => it.json());
  console.log("INITIALIZING " + JSON.stringify(config));
  apiBaseUrl = config["api-base-url"];
  analyticsHost = config["analytics-host"];
}

const getApiBaseUrl = () => apiBaseUrl;
const getAnalyticsHost = () => analyticsHost;

const menuIconClick = () => {
  menuCollapse = !menuCollapse;
  publish("menuCollapse", menuCollapse);
  return menuCollapse;
};

const setLoading = (data = "") => {
  displayLoader = data;
  publish("displayLoader", displayLoader);
  return displayLoader;
};

const setLoadedClient = (loaded) => {
  loadedClient = loaded;
  publish("loadedClient", loadedClient);
  return loadedClient;
}

const setLoadedTenant = (it) => {
  loadedTenant = it;
  publish('loadedTenant', loadedTenant);
  return loadedTenant;
};

const getLoadedTenant = () => loadedTenant;

const setSelectedJourney = (journey => selectedJourney = journey);
const getSelectedJourney = () => selectedJourney;

const getLoadedClient = () => loadedClient;

const publish = (event, data) => PubSub.publish(event, data);
const subscribe = (event, callback) => PubSub.subscribe(event, callback);
const unsubscribe = (event) => PubSub.unsubscribe(event);

const rootConfigProps = {
  initialize,
  getApiBaseUrl,
  getAnalyticsHost,
  menuCollapse,
  menuIconClick,
  publish,
  subscribe,
  unsubscribe,
  PubSub,
  displayLoader,
  setLoading,
  setLoadedClient,
  getLoadedClient,
  setLoadedTenant,
  getLoadedTenant,
  setSelectedJourney,
  getSelectedJourney,
};

export default rootConfigProps;
