import {useContext, useEffect, useState} from "react";
import {RootContext} from "../../contexts/rootContext";

export function Analytics() {

    const rootData = useContext(RootContext);
    const [uri, setUri] = useState<string>();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        const tenant = rootData.getLoadedTenant();
        const client = rootData.getLoadedClient()?.['name'];

        if (tenant && client) {
            const analyticsHost = rootData.getAnalyticsHost();
            const baseUri = analyticsHost ? `https://${analyticsHost}/` : "/";
            setUri(`${baseUri}analytics/?tenant=${tenant}&client=${client}&timezone=${timezone}`);
        } else {
            setUri(undefined);
        }
    }, [rootData]);


    return uri && (
        <div style={{display: "flex", width: "100%", height: "90vh", flexDirection: "column"}}>
            <iframe
                style={{flex: "1 1 auto"}}
                src={uri}
            />
        </div>
    )
}