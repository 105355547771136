import { createContext } from 'react';

interface rootContextType {
    initialize(): any
    getApiBaseUrl() : any
    getAnalyticsHost(): string
    menuCollapse: any
    menuIconClick() : any
    publish(event: any, callback: any) : any
    subscribe(event: any, callback: any) : any
    unsubscribe(event: any) : any
    displayLoader: any
    setLoading(data?: any) : any
    setLoadedClient(value: any): any
    getLoadedClient(): any

    setLoadedTenant(value: any): any
    getLoadedTenant(): any

    setSelectedJourney(j: any): void;
    getSelectedJourney(): any;
}

export const RootContext = createContext<rootContextType | undefined>(undefined);