enum RouteEnum {
  Root = '/',
  Login = '/login',
  Profile = '/profile',
  QrCodeBuilder = '/qr-code-builder',
  Analytics = '/portal/analytics',
  QrCodeDetails = '/qr-code-details',
  CustomStylesEditor = '/custom-styles-editor',
  Brands = '/brands',
  Deployments = '/deployments',
  Deployment = '/deployments/:href',
  NotFound = '/not-found',
  Wildcard = '/*',
}

export default RouteEnum;
