import React, { Suspense, lazy, useState, useEffect, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import RouteEnum from '../models/RouteEnum';
import { RootContext } from '../contexts/rootContext';
import Loading from './loading/loading';
import ProtectedRoute from './../utilities/protectedRoute';
import NavBar from './navBar/navBar';
import SideBar from './side-bar/sideBar';
import { useAuth0 } from '@auth0/auth0-react'
import { getClient } from "../auth/clientContext";
import httpService from "../utilities/httpService";
import ToolTipMainWrapper from '../utilities/toolTipMainWrapper';
import {Analytics} from "./analytics/analytics";


const DeploymentListing = lazy(() => import('./deploymentEditor/deploymentList'));
const DeploymentDetails = lazy(() => import('./deploymentEditor/deploymentDetails/deploymentDetails'));
const CustomStylesListing = lazy(() => import('./customStyleEditor/customStylesListing'));
const CustomStyle = lazy(() => import('./customStyleEditor/customStyle/customStyle'));
const QRCodeListing = lazy(() => import('./qrCodeManagement/qrCodeListing'));
const QRCodeDetails = lazy(() => import('./qrCodeManagement/qrCodeDetails/qrCodeDetails'));
const Profile = lazy(() => import('./profile/profile'));

const NotFound = lazy(() => import('./notFound/notFound'));
const Login = lazy(() => import('./login/login'));

function App() {
  const rootData = useContext(RootContext);

  const [menuCollapse, setMenuCollapse] = useState(false)
  const [rightPanelWidth, setRightPanelWidth] = useState('80%')

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [qrCodeLink, setQrCodeLink] = useState(null)
  const [stylesLink, setStylesLink] = useState(null)
  const [journeys, setJourneys] = useState(null)

  if (!isAuthenticated) {
    return <Loading />
  }

  const { getIdTokenClaims } = useAuth0();

  const menuIconClick = () => {
    setMenuCollapse(rootData.menuIconClick());
    !menuCollapse ? setRightPanelWidth('100%') : setRightPanelWidth('80%');
  };

  useEffect(() => {
    const loadClient = async () => {
      await rootData.initialize();

      const loadedClient = rootData.getLoadedClient();
      if (!loadedClient) {
        const accessToken = await getAccessTokenSilently();
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }

        const apiBaseUrl = rootData.getApiBaseUrl() + "config/";
        const client = getClient(await getIdTokenClaims());
        const root = await httpService.get(apiBaseUrl, config);
        const clientUrlTemplate = root.data._links["igs:client"].href;
        const clientUrl = clientUrlTemplate.replace("{tenant}", client.tenant).replace("{client}", client.name);
        const clientData = (await httpService.get(clientUrl, config)).data;
        rootData.setLoadedClient(clientData);
        rootData.setLoadedTenant(client.tenant);
        setQrCodeLink(clientData._links["igs:qr"].href);
        setStylesLink(clientData._links["igs:style"].href);
        setJourneys(clientData._embedded["igs:journey"]);
      } else {
        const client = rootData.getLoadedClient();
        setStylesLink(client._links["igs:style"].href);
        setJourneys(client._embedded["igs:journey"]);
      }
    }
    loadClient();
  }, []);

  return (
    <React.Fragment>
      <Loading />
      <Suspense fallback={<Loading />}>
        <div className="container-fluid p-0">
          <NavBar />
          <SideBar
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <ToolTipMainWrapper />
          <div style={{ width: rightPanelWidth }} className="rightpane">
            <main className="container">
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path={RouteEnum.Wildcard} element={<NotFound />} />
                </Route>
              </Routes>
            </main>
          </div>
          <section className="root-section-main" style={{ width: rightPanelWidth }} >
            <main className="container-fluid main-embed">
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path={RouteEnum.NotFound} element={<NotFound />} />
                  <Route path={RouteEnum.Login} element={<Login />} />
                  {/* <Route path={RouteEnum.Root} element={<Dashboard />} /> */}
                  <Route path={RouteEnum.Root} element={<Navigate to={RouteEnum.Deployments} />} />
                  <Route path={RouteEnum.Profile} element={<Profile />} />
                  <Route path={RouteEnum.Deployments} element={journeys && <DeploymentListing journeys={journeys} />} />
                  <Route path={RouteEnum.Deployment} element={stylesLink && <DeploymentDetails stylesLink={stylesLink} />} />
                  <Route path={RouteEnum.Brands} element={stylesLink && <CustomStylesListing stylesLink={stylesLink} />} />
                  <Route path={RouteEnum.Brands + '/:name'} element={<CustomStyle />} />
                  <Route path={RouteEnum.QrCodeBuilder} element={qrCodeLink && <QRCodeListing qrCodeLink={qrCodeLink} /> } />
                  <Route path={RouteEnum.QrCodeBuilder + '/:name'} element={<QRCodeDetails />} />
                  <Route path={RouteEnum.Analytics} element={<Analytics />} />
                  {/*<Route path={RouteEnum.Wildcard} element={<NotFound />} />*/}
                </Route>
              </Routes>
            </main>
          </section>
        </div>

      </Suspense>
    </React.Fragment>
  );
}

export default App;
