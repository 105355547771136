enum Constant {
  Next = 'Next',
  Logout = 'Log out',
  Profile = 'Your profile',
  Brands = 'Branding',
  BrandManagement = 'Brand Management',
  BrandBuilder = 'Brand Builder',
  QrCodeBuilder = 'QR Code Builder',
  Analytics = 'Analytics',
  QrCodeManagement = 'QR Code Management',
  DeploymentBuilders = 'Deployment Builder',
  Journeys = 'Journeys',
  CallbackFunction = 'Callback Function',
  ShowDiagnostics = 'Show Diagnostics',
  CustomStyle = 'Select Brand',
  PageStyle  = 'Page Style',
  LightboxType = 'Lightbox Type',
  LightboxLaunchHtml = 'Lightbox Launch Html',
  TemplateStyle  = 'Template Style',
  HostingType  = 'Hosting',
  DeploymentType  = 'Deployment Type',
  Name = 'Name',
  Description = 'Description',
  Parameters = 'Parameters',
  OptinalParameters = 'Optional Parameters',
  Tracking = '(Tracking)',
  ButtonHeight = 'Button Height',
  RoundedCorners = 'Rounded Corners',
  FontSize = 'Font Size',
  AddNewCustomStyle = "Add Brand",
  AddQRCode = "Add QR Code",
  DisplayName = "Name",
  ConfigNameValidation = 'Name must start with a letter and contain only lowercase letters, numbers or underscores.',
  BrandDeleted = 'Brand Deleted',
  BrandAdded = 'Brand Added',
  QRCodeAdded = 'QR Code Added',
  QRCodeUpdated = 'QR Code Updated',
  QRCodeDeleted = 'QR Code Deleted',
  DestinationUrl = 'Destination  URL',
  BrandUpdated = 'Brand Updated',
  ValidHex = 'Please enter a valid Hex Code',
  FontColour = 'Font Colour',
  BorderColour = 'Border Colour',
  Label = 'Label',
  Colour = 'Colour',
  BackgroundColour = 'Background Colour',
  TextColour = 'Text Colour',
  Height = 'Height',
  CornerRadius = 'Corner Radius',
  BorderSize = 'Border Size',
  DeploymentCreated = "Deployment Created",
  CreateDeployment = "Add Deployment",
  DeletePublishedDeployment = "Cannot delete a published configuration",
  DeploymentDeleted = "Deployment Deleted",
  DeploymentUpdated = "Deployment Updated",
  Activate = 'Activate',
  ActivateLatest = 'Activate Latest',
  Deactivate = 'Deactivate',
  DeploymentActivated = 'Deployment Activated',
  DeploymentDeactivated = 'Deployment Deactivated',
  ScriptCopied = 'Script copied.',
  UrlCopied = 'URL copied.',
  ScriptCopiedSuccessfully = 'Script copied successfully.',
  ViewId = 'View Id',
  ConfigAttributeKeyValidation = 'Key must contain only alphabets, numbers, underscores or hyphen.',
  OptionalParametersValidation = 'Key must contain only alphanumeric values, underscores, hyphen or space and cannot start with space.',
  KeyValuePairValidationError = 'Inputs are required for all key-value pairs. Please delete extra pairs.',
  RequiredParameters = 'Parameters are required.',
}

export default Constant;
